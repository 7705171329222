<template>
  <modal
    id="globalAppMessage"
    class="global-app-message"
    ref="modal"
    :initial-open="true"
    :headline="headline"
    headline-type="h2"
  >
    <div
      class="global-app-message__content"
      :class="{
        [`global-app-message__content--icon-${icon}`]: icon
      }"
    >
      <div
        class="global-app-message__message"
        v-if="message"
      >
        {{ message }}
      </div>
      <div
        v-if="error && itsDevMode"
        class="global-app-message__error"
      >
        <h4>
          {{ $t('global_app_error.devmode') }}
        </h4>
        <pre>{{ error }}</pre>
      </div>
    </div>
  </modal>
</template>

<script>
  import Modal from '../modal/modal.vue';
  import pigeon from '../../utilities/js/pigeon/pigeon.js';

  const GlobalAppError = {
    name: 'global-app-message',
    components: {
      Modal
    },
    data() {
      return {
        message: null,
        error: null,
        headline: null,
        icon: null,
        // eslint-disable-next-line
        itsDevMode: _WEBPACK_DEV_MODE
      };
    },
    created() {
      pigeon.subscribe('globalAppMessage:show', this.show.bind(this));
      window._testge = function(data) {
        pigeon.publish('globalAppMessage:show', data);
      }
    },
    methods: {
      show(data) {
        this.headline = data && data.headline || this.$t('global_app_error.message');
        this.message = data && data.message ? data.message : null;
        this.icon = data && data.icon ? data.icon : 'error';
        this.error = data && data.error ? data.error : null;
        this.$refs.modal.open();
      }
    }
  };

  export default GlobalAppError;
</script>
