<template>
  <component
    :is="lazyLoad ? 'lazy-load' : 'ul'"
    :tag="lazyLoad ? 'ul' : null"
    v-if="flagData && flagData.length > 0"
    class="flags"
  >
    <li
      v-for="(flag, index) in flagData"
      :key="index"
      class="flag"
      :class="[`flag--${flag.type}`]"
      @click="clickHandler(flag, $event)"
    >
      <template
        v-if="flag.type === 'award' || flag.type === 'awardInSet'"
      >
        <image-element
          :src="[flag.data]"
          alt=""
        />
      </template>
      <template
        v-else-if="flag.type === 'boxSetLayer'"
      >
        <div class="flag__boxSetLayer">
          <info-popup
            v-if="flags.boxSetLayer"
            :show-close-icon="true"
            position="bottom-left"
          >
            <template #label>
              <div class="flag__boxSetLayer-content">
                <span
                  class="flag__boxSetLayer-text"
                  v-html="$t('flags.boxSetLayer.text')"
                />
                <image-element
                  :src="[settings.flags.src.boxSetImage]"
                  alt=""
                />
              </div>
            </template>
            {{ $t('flags.boxSetLayer.info', [flags.boxSetLayer.noOfBottlesForBox]) }}
          </info-popup>
        </div>
      </template>
      <template
        v-else-if="flag.type === 'boxSetUrl'"
      >
        <image-element
          :src="[settings.flags.src.boxSetImage]"
          alt=""
        />
      </template>
      <span
        v-else-if="flag.type === 'quote'"
      >
        <span
          class="flag__quote"
          v-html="flag.data[0]"
        />
        <span
          class="flag__author"
          v-html="flag.data[1]"
        />
      </span>
      <template
        v-else-if="flag.type === 'takeAndPayBenefitText' || flag.type === 'takeAndPayBenefitAppliedItemsText'"
      >
        <span
          class="flag__takeAndBenefit"
          v-html="flag.data"
        />
      </template>
      <template
        v-else-if="flag.type === 'nachhaltigkeit'"
      >
        <a
          :href="$t('flags.nachhaltigkeit.link')"
          v-if="enableLinks"
          class="flag--link"
          v-html="flag.data"
          :target="getTarget(flag.type)"
        />
        <span
          v-else
          v-html="flag.data"
        />
      </template>
      <span
        v-else
        v-html="flag.data"
      />
    </li>
  </component>
</template>

<script setup lang="ts">
  import {computed} from 'vue';
  import {TranslateResult, useI18n} from 'vue-i18n';
  import type Flags from '../../types/Flags';
  import type FlagList from '../../types/FlagList';
  import LazyLoad from '../lazyLoad/lazyLoad.vue';
  import InfoPopup from '../infoPopup/infoPopup.vue';
  import ImageElement from '../imageElement/imageElement.vue';
  import diContainer from '../../utilities/js/diContainer/diContainer';
  import {ProductDetailsSettings} from '../productDetails/productDetailsSettings';

  LazyLoad.name // LazyLoad is in use through component, but IDE doesn't recognize it

  type FlagData = {
    type: keyof Flags,
    data: string | [string, string] | TranslateResult | number
  }

  const props = withDefaults(defineProps<{
    flags: Flags,
    lazyLoad?: boolean,
    limit?: number,
    awardLimit?: number,
    whiteList?: FlagList,
    limitIgnoringFlags?: FlagList,  // Flags that are not counted toward the limit
    enableLinks?: boolean,
    targetBlankFlags?: string[],
    withSavingsMax?: boolean
  }>(), {
    lazyLoad: true,
    limit: 3,
    awardLimit: 3,
    whiteList: () => [],
    limitIgnoringFlags: () => [],
    enableLinks: false,
    targetBlankFlags: () => [],
    withSavingsMax: false
  });

  const {t} = useI18n();
  const settings: ProductDetailsSettings = diContainer.get('productDetailsSettings');
  const defaultFlagOrder: FlagList = ['takeAndPayBenefitText', 'takeAndPayBenefitAppliedItemsText', 'free', 'new', 'newVintage', 'savings', 'savingsMax', 'awardInSet', 'award', 'stoerer', 'nachhaltigkeit', 'bio', 'vegan', 'quote', 'boxSetUrl', 'exclusiveText'];

  const flagData = computed(() => {
    const flagList: FlagData[] = [];
    const flagOrder = props.whiteList || defaultFlagOrder;
    let flagLimit = props.limit;

    flagOrder.forEach((flagType) => {
      if (props.flags.subscription && flagType === 'subscription') {
        flagList.push({
          type: 'subscription', data: t('flags.subscription')
        });
      } else if (props.flags.new && flagType === 'new') {
        flagList.push({
          type: 'new', data: t('flags.new')
        });
      } else if (props.flags.newVintage && flagType === 'newVintage') {
        flagList.push({
          type: 'newVintage', data: t('flags.newVintage')
        });
      } else if (props.flags.savings && flagType === 'savings') {
        if (props.withSavingsMax && parseInt(props.flags.savings) > 50) {
          flagList.push({
            type: 'savingsMax', data: t('flags.savingsMax')
          })
        } else if (props.flags.savings !== '100') {
          flagList.push({
            type: 'savings', data: `${settings.flags.minusText}${props.flags.savings}${settings.flags.percentText}`
          })
        }
      } else if (props.flags.awardInSet && flagType === 'awardInSet') {
        flagList.push({
          type: 'awardInSet', data: settings.flags.src.awardInSetImage
        })
      } else if (props.flags.awardImageUrls && props.flags.awardImageUrls.length && flagType === 'award') {

        const awardLimit = Math.min(props.awardLimit, props.flags.awardImageUrls.length);

        for (let ndx = 0; ndx < awardLimit; ndx++) {
          flagList.push({
            type: 'award', data: props.flags.awardImageUrls[ndx]
          });
        }
      } else if (props.flags.bottleSize && flagType === 'bottleSize') {
        flagList.push({
          type: 'bottleSize', data: props.flags.bottleSize
        });
      } else if (props.flags.nonAlcoholic && flagType === 'nonAlcoholic') {
        flagList.push({
          type: 'nonAlcoholic', data: t('flags.nonAlcoholic')
        });
      } else if (props.flags.nachhaltigkeit && flagType === 'nachhaltigkeit') {
        flagList.push({
          type: 'nachhaltigkeit', data: t('flags.nachhaltigkeit')
        });
      } else if (props.flags.bio && flagType === 'bio') {
        flagList.push({
          type: 'bio', data: t('flags.bio')
        });
      } else if (props.flags.vegan && flagType === 'vegan') {
        flagList.push({
          type: 'vegan', data: t('flags.vegan')
        });
      } else if (props.flags.free && flagType === 'free') {
        flagList.push({
          type: 'free', data: t('flags.free')
        });
      } else if (props.flags.quote && flagType === 'quote') {
        flagList.push({
          type: 'quote', data: [props.flags.quote.text, props.flags.quote.author]
        });
      } else if (props.flags.takeAndPayBenefitText && flagType === 'takeAndPayBenefitText') {
        flagList.push({
          type: 'takeAndPayBenefitText', data: props.flags.takeAndPayBenefitText
        });
      } else if (props.flags.takeAndPayBenefitAppliedItemsText && flagType === 'takeAndPayBenefitAppliedItemsText') {
        flagList.push({
          type: 'takeAndPayBenefitAppliedItemsText', data: props.flags.takeAndPayBenefitAppliedItemsText
        });
      } else if (props.flags.boxSetUrl && flagType === 'boxSetUrl') {
        flagList.push({
          type: 'boxSetUrl', data: props.flags.boxSetUrl
        });
      } else if (props.flags.boxSetLayer?.noOfBottlesForBox && flagType === 'boxSetLayer') {
        flagList.push({
          type: 'boxSetLayer', data: null
        });
      } else if (props.flags.stoerer && props.flags.stoerer.length && flagType === 'stoerer') {
        for (let ndx = 0; ndx < props.flags.stoerer.length; ndx++) {
          flagList.push({
            type: 'stoerer', data: props.flags.stoerer[ndx]
          });
        }
      } else if (props.flags.personalized && flagType === 'personalized') {
        flagList.push({
          type: 'personalized', data: t('flags.personalized')
        });
      } else if (props.flags.exclusiveText && flagType === 'exclusiveText') {
        flagList.push({
          type: 'exclusiveText', data: props.flags.exclusiveText
        });
      }
    });

    if (props.limitIgnoringFlags) {
      flagList.forEach((flag) => {
        if (props.limitIgnoringFlags.includes(flag.type)) {
          flagLimit++;
        }
      });
    }

    return flagList.slice(0, flagLimit);
  });


  function getTarget(flagType: string) {
    if (props.targetBlankFlags.indexOf(flagType) >= 0) {
      return '_blank';
    }
    return '_self';
  }

  function clickHandler(flag: FlagData, event: Event) {
    if (flag.type === 'award') {
      location.hash = '#awards';
    }

    if (flag.type === 'awardInSet') {
      location.hash = '#setContains';
    }

    if (flag.type === 'boxSetUrl') {
      event.preventDefault();
      window.location.href = flag.data as string;
    }
  }
</script>
